












































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import {
    getList, getListContactById, getListCustomFields, deleteListContactById, sendOptin,
} from '@/api/consoleApi/recipients'
import {
    ContactStatusEnum,
    IContact,
} from '@/api/consoleApi/types'
import axios from 'axios'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import {
    CustomFieldTypeEnum,
    CustomField,
} from '@/utils/customFields'
import VsTabs from '@/components/VsTabs/Index.vue'
import VsListNameModal from '@/modules/lists/components/VsListNameModal/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import { UserModule } from '@/store/modules/user'
import { get, orderBy } from 'lodash'
import { getSuppressions } from '@/api/consoleApi/suppressions'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsTabsHeading from '@/components/VsTabsHeading/Index.vue'

@Component({
    name: 'ContactIndex',
    components: {
        VsContainer,
        VsTabs,
        VsListNameModal,
        VsConfirm,
        VsSectionHeader,
        VsDropdownButton,
        VsTabsHeading,
    },
})
export default class extends Vue {
    private list: any = null
    private loading = false
    private customFields: CustomField[] = []
    private contact: IContact | null = null
    private emailSuppression: any = false
    private smsSuppression: any = false
    private customFieldsValues: {[key: string]: any} | null = null
    private startCustomFieldsValues: {[key: string]: any} | null = null
    $refs: any

    get listId () {
        return this.$route?.params?.listId
    }

    get contactId () {
        return this.$route?.params?.contactId
    }

    get activeTab () {
        return this.$route.name
    }

    get hasEmail () {
        return this.customFields.find(el => el.type === CustomFieldTypeEnum.email)
    }

    get hasSms () {
        return this.customFields.find(el => el.type === CustomFieldTypeEnum.mobile)
    }

    get hasUniqueField () {
        return this.customFields.find(el => el.validation.unique)
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get hasTag () {
        return this.userRules.tag
    }

    get isOptIn () {
        return this.contact && this.contact.subscription.status === ContactStatusEnum.optIn
    }

    get contactName () {
        if (!this.contact) return ''
        if (this.customFields.length === 0) return 'Id contatto: ' + this.contact.id
        if (this.hasEmail && this.hasEmail.validation.unique && this.contact.email_address) return this.contact.email_address
        if (this.hasSms && this.hasSms.validation.unique && this.contact.mobile) return this.contact.mobile
        return 'Id contatto: ' + this.contact.id
    }

    get isEplusDisabled () {
        return this.userRules.eplusLicense === 0
    }

    get tabs () {
        const tabs = [
            {
                label: this.$t('lists.contact.tabs.details'),
                id: 'contactDetails',
                to: {
                    name: 'contactDetails',
                },
            },
            {
                label: this.$t('lists.contact.tabs.deliverability'),
                id: 'contactDeliverability',
                to: {
                    name: 'contactDeliverability',
                },
            },
            {
                label: this.$t('lists.contact.tabs.tags'),
                id: 'contactTags',
                disabled: !this.hasTag,
                iconName: !this.hasTag ? 'lock' : '',
                to: {
                    name: 'contactTags',
                },
                clickDisabled: this.$root.$vsOpenBlockedModal,
            },
            {
                label: this.$t('lists.contact.tabs.interests'),
                id: 'contactInterests',
                to: {
                    name: 'contactInterests',
                },
            },
            {
                label: this.$t('lists.contact.tabs.activities'),
                id: 'contactActivities',
                to: {
                    name: 'contactActivities',
                },
            },
            {
                label: this.$t('lists.contact.tabs.eplus'),
                id: 'contactEplus',
                disabled: this.isEplusDisabled,
                iconName: this.isEplusDisabled ? 'lock' : '',
                to: {
                    name: 'contactEplus',
                },
                clickDisabled: this.openBlockedModal,
            },
        ]

        return tabs
    }

    @Watch('listId', { immediate: true })
    private async getList () {
        try {
            const resp = await getList(this.listId)
            this.list = resp.data.data
            delete this.list.integration
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) {
                    this.$root.$vsToast({
                        timeout: 3000,
                        heading: 'Lista non trovata',
                        aspect: VsToastAspectEnum.alert,
                    })
                    this.$router.replace({ name: 'listsIndex' })
                    return
                }
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore nel reperire i dati della lista, riprova più tardi',
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }

    private openBlockedFunctionality (e: any, isBlocked: boolean) {
        if (isBlocked) {
            e.preventDefault()
            this.$root.$vsOpenBlockedModal()
        }
    }

    private async getCustomFields () {
        const resp = await getListCustomFields(this.listId, {
            limit: 1000,
        })
        this.customFields = orderBy(resp.data.data, 'validation.required', 'desc')
    }

    @Watch('listId', { immediate: true })
    private async generateCustomFields () {
        this.loading = true
        try {
            this.contact = null
            this.customFieldsValues = null
            this.startCustomFieldsValues = null
            await this.getCustomFields()
            await this.getContact()
            this.generateCustomFieldsValuesObject()
            this.getSuppressions()
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    private async formSaved () {
        await this.getContact()
        this.generateCustomFieldsValuesObject()
        this.getSuppressions()
    }

    private async getContact () {
        try {
            const resp = await getListContactById(this.listId, this.contactId, { include: 'tags' })
            this.contact = resp.data.data
        } catch (e) {
            const errorCode = get(e, 'response.status', 0)
            if (errorCode === 404) {
                this.$router.replace({
                    name: 'contacts',
                })
            }
            console.log(e)
        }
    }

    private async getSuppressions () {
        if (!this.contact) return
        try {
            if (this.contact.email_address) {
                const resp = await getSuppressions({
                    search: `email_address:${this.contact.email_address}`,
                })
                this.emailSuppression = resp.data.data.length > 0 ? resp.data.data[0] : false
            }
            if (this.contact.mobile) {
                const resp = await getSuppressions({
                    search: `mobile:${this.contact.mobile}`,
                })
                this.smsSuppression = resp.data.data.length > 0 ? resp.data.data[0] : false
            }
        } catch (e) {
            this.emailSuppression = false
            this.smsSuppression = false
        }
    }

    private generateCustomFieldsValuesObject () {
        const newCustomFieldsValues: {[key: string]: any} = {}
        for (const field of this.customFields) {
            newCustomFieldsValues[field.id + ''] = this.getCustomFieldStartValue(field)
        }
        this.customFieldsValues = newCustomFieldsValues
        this.startCustomFieldsValues = JSON.parse(JSON.stringify(newCustomFieldsValues))
    }

    private getCustomFieldStartValue (customField: CustomField) {
        if (!this.contact) return ''
        if (customField.type === CustomFieldTypeEnum.email) return this.contact.email_address
        if (customField.type === CustomFieldTypeEnum.mobile) return this.contact.mobile

        const findCfValue = this.contact.custom_fields.find((el: any) => el.id === customField.id)

        if (findCfValue && findCfValue.value && findCfValue.value !== '0000-00-00') return findCfValue.value

        if (customField.default_value) return customField.default_value
        if (
            customField.type === CustomFieldTypeEnum.checkboxes
        ) {
            return []
        }
        return ''
    }

    private async deleteListContactById () {
        try {
            await this.$refs.deleteListConfirm.openConfirm()
        } catch (e) {
            console.log(e)
            return
        }
        this.loading = true
        try {
            await deleteListContactById(this.listId, this.contactId)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Contatto eliminato con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.$router.replace({
                name: 'contacts',
                params: {
                    listId: this.listId,
                },
            })
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante la cancellazione del contatto',
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private async sendConfirmationEmail () {
        try {
            await this.$refs.confirmationEmailConfirm.openConfirm()
        } catch (e) {
            console.log(e)
            return
        }
        this.loading = true
        try {
            await sendOptin(this.listId, this.contactId)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Mail di conferma inviata con successo',
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante l\'invio dell\'email di conferma',
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private openBlockedModal () {
        this.$root.$vsOpenBlockedModal()
    }
}
